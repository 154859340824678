import { gqlSelect, useAsyncQuery, useNuxtApp, useState } from '#imports'

type UseFeatureProps = {
  employeeUuid?: string
  organizationUuid?: string
  segmentUuid?: string
}

export const useFeature = async ({ employeeUuid, organizationUuid, segmentUuid }: UseFeatureProps) => {
  const nuxtApp = useNuxtApp()
  const featuresState = useState('features', () => ({
    writeSegment: true,
    readSegment: true,
  }))

  if (employeeUuid || organizationUuid || segmentUuid) {
    const { data } = await useAsyncQuery('GetFeatures', {
      employeeUuid,
      organizationUuid,
      segmentUuid,
    }, {
    // 2回目以降のクエリ実行時にキャッシュデータがあればそれを使う
      getCachedData: key => nuxtApp.payload.data[key] || nuxtApp.static.data[key],
    },
    )
    const employeeData = gqlSelect(data.value?.me.employee, 'Employee')
    const features = gqlSelect(employeeData?.features, 'EmployeeFeatures')

    if (features) {
      featuresState.value.writeSegment = features.writeSegment
      featuresState.value.readSegment = features.readSegment
    }
  }

  return ({
    writeSegment: featuresState.value.writeSegment,
    readSegment: featuresState.value.readSegment,
  })
}
